.section {
  margin-bottom: 20px;
}

.list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

span[contenteditable] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 200px;
  margin-right: 10px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
}

button {
  margin-left: 10px;
} 